import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import BankIdLoader from '@components/BankIdLoader';
import classnames from 'classnames';

import * as styles from './styles.module.css';

export default function LanaContainer({ children, padded = false }) {
  const { isAuthing, isSigning } = useSelector(state => state.api.bankId);
  const showingBankIdLoader = isAuthing || isSigning;
  if (showingBankIdLoader) {
    return <BankIdLoader />;
  }
  const containerClassNames = classnames({
    [styles.contentContainer]: true,
    [styles.navPadding]: padded,
  });
  return <div className={containerClassNames}>{children}</div>;
}

LanaContainer.propTypes = {
  children: PropTypes.node.isRequired,
  padded: PropTypes.bool,
};
