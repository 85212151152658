import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import { useMedia } from '@src/lib/hooks';

import Contact from '../../components/contact';

import Hero from '../../components/hero';

import LanaContainer from './lanaContainer';
import Layout from '@components/layout';

export default function ApplicationCompletePage({ data }) {
  const pageContent =
    data.contentfulPage.pageContent[0].bodyText.childMarkdownRemark.html;
  const title = data.contentfulPage.headLine;
  const isMobile = useMedia(['(max-width: 768px)'], [true], false);
  return (
    <Layout path="/lana/klar" newStyle>
      <Hero title={title} breadcrumbs={[{ name: 'Hem', path: '/' }]} />
      <LanaContainer padded={isMobile}>
        <div
          className="text-small"
          dangerouslySetInnerHTML={{ __html: pageContent }}
        ></div>
      </LanaContainer>
      <Contact hideFAQ />
    </Layout>
  );
}

ApplicationCompletePage.propTypes = {
  data: PropTypes.object,
};

export const query = graphql`
  query ApplicationCompletePageQuery {
    contentfulPage(pageUrl: { eq: "/lana/klar" }) {
      headLine
      pageContent {
        ... on ContentfulDescriptionComponent {
          bodyText {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;
